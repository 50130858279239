// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDRLoc9WMYgiZSjm29c_BSyuwbumwYk9o8",
    authDomain: "bbms-1283c.firebaseapp.com",
    databaseURL: "https://bbms-1283c.firebaseio.com",
    projectId: "bbms-1283c",
    storageBucket: "bbms-1283c.appspot.com",
    messagingSenderId: "280681404231",
    appId: "1:280681404231:web:86c2a944a9bdfa07"
  },
  // openaiApiKey: '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'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
