 <nav class="navbar box-shadow app-header" [class.fixed-top]="settings.theme.navbarIsFixed" [class.logo-visible]="showInfoContent">
    <div class="d-flex flex-column w-100">
        <div class="d-flex justify-content-start align-items-center top-navbar">
            <div class="text-white font-weight-bold" style="margin-left: 150px; font-size: 11px; line-height: 11px">
              <div>{{member.lName}}, {{member.fName}} {{member.mName}} | <span class="text-warning font-weight-bold">IP ADDRESS: {{ipAddress}}</span> |
                <span *ngIf="member.authAdmin" class="badge badge-warning badge-pill m-1">ADMIN</span>
                <span *ngIf="member.authJudge" class="badge badge-success badge-pill m-1">JUSTICE</span>
                <span *ngIf="member.authJudgeClerk" class="badge badge-success badge-pill m-1">JUDICIAL ASSISTANT</span>
                <span *ngIf="member.authAttorney" class="badge badge-success badge-pill m-1">PROSECUTION</span>
                <span *ngIf="member.authPrivAttorney" class="badge badge-success badge-pill m-1">ATTORNEY</span>
                <span *ngIf="member.authClerk" class="badge badge-success badge-pill m-1">INTAKE/BDOCS</span>
                <span *ngIf="member.authRegistrar" class="badge badge-success badge-pill m-1">CRIMINAL REGISTRY</span>
                <span *ngIf="member.authRegistrarClerk" class="badge badge-success badge-pill m-1">CRIMINAL REGISTRY STAFF</span>
                <br><span class="text-warning">Member Since:</span> {{member.memberSince}} <span class="text-warning">Last Login:</span> {{member.lastLogin}}
              </div>
            </div>

            <div class="top-rigth-icons ml-auto">
                <span class="mb-1 mt-0 mr-1 btn btn-outline-danger btn-sm" style="padding: 0px 2px; font-size: 14px; border-radius: 8px; line-height: 19px" (click)="doCheckForUpdates()" >
                        <i class="fa fa-gear" aria-hidden="true"></i> CHECK FOR UPDATES
                </span>
                <span class="hidden-xs-down">
                    <app-user-menu></app-user-menu>
                </span>
                <span class="mb-1 mt-0 btn btn-outline-warning btn-sm" style="padding: 0px 2px; font-size: 14px; border-radius: 8px; line-height: 19px" (click)="logout()" >
                        <i class="fa fa-sign-out" aria-hidden="true"></i> LOGOUT
                </span>
            </div>

        </div>

        <div class="horizontal-menu-outer" *ngIf="settings.theme.menu == 'horizontal'">

            <app-horizontal-menu *ngIf="showHorizontalMenu"  [menuItems]="menuItems"></app-horizontal-menu>
            <app-vertical-menu *ngIf="!showHorizontalMenu"  [menuItems]="menuItems"></app-vertical-menu>

        </div>
    </div>
</nav>
