// NOTE: You will need to create a Firebase Account for testing. Once created, copy your information into the firebaseConfig object.
// The environmental variables shown here will not allow you to test code, as they are for the live system. Once you change your config, do not push this file
export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyDRLoc9WMYgiZSjm29c_BSyuwbumwYk9o8",
    authDomain: "bbms-1283c.firebaseapp.com",
    databaseURL: "https://bbms-1283c.firebaseio.com",
    projectId: "bbms-1283c",
    storageBucket: "bbms-1283c.appspot.com",
    messagingSenderId: "280681404231",
    appId: "1:280681404231:web:86c2a944a9bdfa07"
  }
};
